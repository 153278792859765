// 帖子列表
import request from '../request'

export default class List {

    /**
* 帖子列表
* @param {*} params
* @returns
*/
    static articleCardList(params) {
        return request.get('/api/v1.articlecurrency/articleCardList', {
            params
        })
    }
    //     /**
    // * 搜索
    // * @param {*} params
    // * @returns
    // */
    //     static articleSearch(params) {
    //         return request.get('/api/v1.articlesearch/articleSearch', {
    //             params
    //         })
    //     }

    /**
* 搜索
* @param {*} params
* @returns
*/
    static articleSearch(params) {
        return request.get('/api/v1/Articlesearch/articleSearchWechat', {
            params
        })
    }
    /**
* 特殊类型查询
* @param {*} params
* @returns
*/
    static articleCardTypeList(params) {
        return request.get('/api/v1.articlecurrency/articleCardTypeList', {
            params
        })
    }
    /**
* 帖子热榜
* @param {*} params
* @returns
*/
    static hotArticlelist() {
        return request.get('/api/v1.articlecurrency/hotArticlelist')
    }
    /**
* 文明城市与做木鸟
* @param {*} params
* @returns
*/
    static articleTypeartList(params) {
        return request.get('/api/v1.articlecurrency/articleTypeartList', {
            params
        })
    }
    /**
* 总热榜
* @param {*} params
* @returns
*/
    static allhotArticlelist(params) {
        return request.get('/api/v1/articlecurrency/allhotArticlelist', {
            params
        })
    }
    /**
* 精选回复
* @param {*} params
* @returns
*/
    static refinedArticle(params) {
        return request.get('/api/v1/articlecurrency/refinedArticle', {
            params
        })
    }
}